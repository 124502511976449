import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo";

interface Props {}

const SplintovaciKlestePage = (props: Props) => {
  return (
    <Layout>
      <SEO title="Splintovací kleště" />
      <h1>Splintovací kleště</h1>
      <p className="text-justify">
        Papírové visačky se k výrobkům připevňují pomocí splintovacích kleští a
        plastových sponek, tzv. splintů. Splintovací kleště se vyrábějí ve dvou
        provedeních - F(fine) a S(standard). Toto označení určuje průměr jehly,
        kterou se musí propíchnout tkanina výrobku při připevňování visačky.
        Kleště kategorie F mají jehlu tenčí než kleště typu S. V nabídce máme
        tři druhy kleští F a tři druhy kleští S. Jejich použití je téměř stejné,
        drobné rozdíly jsou pouze při zakládání splintů do kleští. Rozdílná je
        však jejich kvalita s ohledem na pracovní zatížení.
      </p>
      <div className="flex justify-center mb-2">
        <StaticImage
          src="../images/splintovaci-kleste/troje-kleste.png"
          alt="Splintovací kleště"
          className="mb-10 mt-10"
        />
      </div>
      <div className="grid grid-cols-4 gap-10">
        <div className="col-span-4 md:col-span-2 text-justify">
          Spotřební materiál ke kleštím jsou splinty. Ty se vyrábějí v různých
          délkách, v nabídce máme délky 7, 11, 15, 20, 25, 35, 40 a 50 mm pro
          kategorii F a 20, 25, 40, 50 pro kategorii S. Splinty jsou spojeny po
          sto nebo padesáti kusech do „zásobníků“, které se vkládají do kleští a
          na jedno založení tedy umožní připevnit 50 nebo 100 visaček. Nabízíme
          i variantu s háčkem na zavěšení (pouze pro kategorie S) v délkách 25 a
          35mm. Alternativním způsobem připevňování visaček je použití
          pojistných nití. Používají se např. tam, kde není možné propíchnout
          výrobek jehlou splintovacích kleští, ale je k dispozici např. poutko
          nebo knoflíková dírka. Použití je pracnější než u splintovacích kleští
          (musí se ručně provléknout visačkou a výrobkem a pak ručně zajistit
          proti rozpojení) a i niť je dražší než splint. Odpadá však investice
          do nákupu kleští. Nitě nabízíme v délkách 50, 75 a 125 mm.
        </div>
        <StaticImage
          className="col-span-4 md:col-span-2"
          src="../images/splintovaci-kleste/splinty.png"
          alt="Splinty"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>
    </Layout>
  );
};

export default SplintovaciKlestePage;
